* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  letter-spacing: -1px;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -1px;
  line-height: 2rem;
  font-size: 1rem;
}

body[data-page="home"] {
  overflow: hidden;
}

h1, h2, h3 {
  margin: 0;
  padding: 0;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: normal;
}

.a-red {
  color: #e51f2c;
  font-weight: 700;
  transition: color 200ms ease;
}

.a-red:hover {
  color: #000000;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.7);
  z-index: -1001;
} 